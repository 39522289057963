import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IUser } from '../../interfaces/user.interface';
import { TextField } from '@mui/material';
import { FloristDropdown } from '../../components/base/Dropdown';
import { FloristButton } from '../../components/base/Button';
import { useMember } from './useMember';
import { ERole } from '../../types/enums/roles.enum';
import { EStores } from '../../types/enums/taken-by.enum';
import { ConfirmationDialog } from '../../components/base/ConfirmDialog';
import { TOperations } from '../../types/operations.type';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
interface IMemberProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  user?: IUser;
  action: 'edit' | 'view' | 'create';
}
export default function MemberModal(props: IMemberProps) {
  const {
    email,
    firstName,
    handleUpdate,
    handleAddUser,
    lastName,
    role,
    setEmail,
    setFirstName,
    setLastName,
    setRole,
    store,
    setStore,
    handleDeleteUser,
    confirmationDialogOperation,
    setConfirmDialogOperation
  } = useMember(props.setIsOpen, props.user);

  const operations: Record<TOperations, () => Promise<void> | undefined> = {
    ADD: handleAddUser,
    EDIT: handleUpdate,
    DELETE: handleDeleteUser,
    CANCEL: async () => props.setIsOpen(false),
    VIEW: function (): Promise<void> {
      throw new Error('Function not implemented.');
    }
  }
  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={() => props.setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="w-full p-4 border-collapse border border-secondary mb-2">
            {props.action !== 'view' ? (
              <div className="grid grid-cols-4 grid-rows-2 gap-y-5 gap-x-10">
                <TextField
                  className="col-span-2"
                  size="small"
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  className="col-span-2"
                  size="small"
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                  className="col-span-2"
                  size="small"
                  label="Email"
                  value={email}
                  type={'email'}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FloristDropdown
                  className="col-span-2"
                  options={Object.entries(ERole).map(([k, v]) => ({ label: k, value: v }))}
                  value={role}
                  onChange={(val) => {
                    setRole(val as ERole);
                  }}
                  selectId={'user-role-select'}
                  selectLabel={'User Role'}
                  key={'user-role-setting'}
                />
                <FloristDropdown
                  className="col-span-2"
                  options={Object.values(EStores).map((v) => ({ label: v.toUpperCase(), value: v }))}
                  value={store}
                  onChange={(val) => {
                    setStore(val as EStores);
                  }}
                  selectId={'user-store-select'}
                  selectLabel={'Store'}
                  key={'user-store-setting'}
                />
                <div className="flex flex-row justify-center gap-4 col-span-2 col-start-2">
                  <FloristButton className="!bg-error" label={props.action === 'create' ? 'Cancel' : 'Delete'} onClick={() => {
                    if (props.action !== 'create')
                      setConfirmDialogOperation('DELETE')
                    else
                      props.setIsOpen(false)
                    // handleDeleteUser();
                    // props.setIsOpen()
                  }} />
                  <FloristButton
                    label={props.action === 'create' ? 'Add' : 'Update'}
                    onClick={() => {
                      setConfirmDialogOperation(props.action === 'create' ? 'ADD' : 'EDIT')
                    }}
                  />
                </div>
                <ConfirmationDialog
                  isOpen={!!confirmationDialogOperation}
                  title={`Confirm ${(confirmationDialogOperation?.[0] || '') + confirmationDialogOperation?.slice?.(1)?.toLocaleLowerCase()}`}
                  content={`Are you sure you want to ${confirmationDialogOperation?.toLowerCase()} this user?`}
                  onConfirm={() => confirmationDialogOperation && operations[confirmationDialogOperation]()}
                  onCancel={() => setConfirmDialogOperation(undefined)}
                />
              </div>
            ) : (
              <>
                <h5 className="tracking-widest text-2xl">{`${firstName} ${lastName}`}</h5>
                <p className="text-secondary-heading text-xs capitalize">{role}</p>
                <h5 className="tracking-wide text-base mt-4">{'Email'}</h5>
                <p className="text-secondary-heading text-xs">{email}</p>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}