import { EmptyObject } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { IUser } from '../../interfaces/user.interface';
import { ICreateUserDto } from '../user/dto/create.dto';
import { IAuthDto, IForgotPasswordRequestDto, ILoginResponseDto } from './dto/auth.dto';

export const authApi = createApi({
  reducerPath: 'auth',
  tagTypes: ['users', 'user'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL || '/api',
    headers: {
      'x-api-key': 'DhKY3vDqDp8!ym$7jUD@K4PXu7PPkd',
    },
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${Cookies.get('access_token')}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponseDto, IAuthDto>({
      query: (filters) => ({
        method: 'POST',
        url: 'auth/login',
        body: filters,
      }),
    }),
    logout: builder.query<void, EmptyObject>({
      query: () => ({
        url: 'auth/logout',
      }),
    }),
    forgotPasswordRequest: builder.mutation<{ success: boolean }, IForgotPasswordRequestDto>({
      query: (filters) => ({
        method: 'POST',
        url: 'auth/reset-password',
        body: { email: filters.email },
      }),
    }),
    createUserAndSendInvitation: builder.mutation<IUser, ICreateUserDto>({
      query: (createDto) => ({
        method: 'POST',
        url: 'auth/add-user',
        body: createDto,
      }),
      invalidatesTags: [{ type: 'users' }],
    }),
  }),
});
export const {
  useLoginMutation,
  useCreateUserAndSendInvitationMutation,
  useLogoutQuery,
  useForgotPasswordRequestMutation,
} = authApi;
