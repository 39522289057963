import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetOrdersListQuery } from '../../store/order/order.api';
import { useGetUserQuery } from '../../store/user/user.api';
import { IFilters } from '../../interfaces/order-filters.interface';
import { EOrderStatus } from '../../types/enums/status.enum';

export const useOrders = (props?: { orderStatus?: EOrderStatus }) => {
  const [limit, setLimit] = useState<number>(20);
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState<IFilters>({ 'order-status': props?.orderStatus });

  const { data } = useGetOrdersListQuery({ limit, page, ...filters });
  const { data: currentUser } = useGetUserQuery({})

  const navigate = useNavigate();

  return {
    limit: limit,
    total: data?.total,
    page: page,
    records: data?.records,
    setPage,
    setLimit,
    navigate,
    filters,
    setFilters,
    currentUser: currentUser?.user
  };
};
