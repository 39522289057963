import { IOrder } from '../interfaces/order.interface';
import { EFulfillmentStatus } from '../types/enums/fulfillment-status.enum';
import { EOrderStatus } from '../types/enums/status.enum';

export const isFulfilled = (order: IOrder) => {
  return (
    order.fulfillmentStatus &&
    order.orderStatus !== EOrderStatus.CANCELLED &&
    [
      EFulfillmentStatus.DELIVERED,
      EFulfillmentStatus.COLLECTED,
      EFulfillmentStatus.SAFE_PLACE,
      EFulfillmentStatus.NEIGHBOR,
    ].includes(order.fulfillmentStatus)
  );
};
