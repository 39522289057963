import { TextField, InputAdornment, BaseTextFieldProps } from '@mui/material';
import * as React from 'react';

export interface IFloristTextFieldProps extends BaseTextFieldProps {
  label: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  value?: string | number;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  type?: React.HTMLInputTypeAttribute;
  className?: string;
  errorMessage?: string;
  // Add prefix property
  prefix?: string;
}

export function FloristTextField(props: IFloristTextFieldProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.disabled) {
      const value = e.target.value;
      props.onChange?.(value === '' ? '' : value); // Handle empty input
    }
  };

  return (
    <>
      <TextField
        {...props}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
        onChange={handleChange}
        value={props.value ?? ''}
        size={props.size}
        type={props.type}
        className={props.className}
        error={!!props.errorMessage}
        InputLabelProps={
          props.InputLabelProps || {}
        }
        // Add InputProps to include prefix as an adornment
        InputProps={{
          startAdornment: props.prefix && (
            <InputAdornment position="start">{props.prefix}</InputAdornment>
          ),
          ...(props.type === 'number' ? {
            sx: {
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none',
              },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
            }
          } : {})
        }}
      />
      {props.errorMessage && <p className="text-error">{`*${props.errorMessage}`}</p>}
    </>
  );
}
