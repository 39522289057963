import * as React from 'react';

export interface IHeadingProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  additionalClassNames?: string[];
  label: string;
}

export function FloristSmallHeading({ additionalClassNames = [], label, onClick, ...extra }: IHeadingProps) {
  return (
    <h3
      {...extra}
      className={['w-full', 'text-3xl', 'font-normal', 'text-heading', ...additionalClassNames].join(' ')}
      onClick={onClick}
    >
      {label}
    </h3>
  );
}
