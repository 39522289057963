import * as React from 'react';
import { Link } from 'react-router-dom';
import { ERole } from '../../types/enums/roles.enum';
import LogoSVG from '../../assets/logo/logo.svg';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CreateIcon from '@mui/icons-material/Create';
import GroupsIcon from '@mui/icons-material/Groups';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';

export interface ISidebarProps {
  role: ERole;
}

export default function Sidebar(props: ISidebarProps) {
  const items = [
    { label: 'All Orders', link: '/all-orders', Icon: FormatListBulletedIcon },
    { label: 'New Order', link: '/new-order', Icon: CreateIcon },
    { label: 'Reports', link: '/reports', Icon: QueryStatsIcon },
    { label: 'Archived Orders', link: '/archived-orders', Icon: DeleteIcon },
    { label: 'Team', link: '/team', Icon: GroupsIcon, role: [ERole.ADMIN] },
    { label: 'Settings', link: '/settings', Icon: SettingsIcon },
  ];
  return (
    <div className="h-screen col-span-1 text-black">
      <img src={LogoSVG} className="w-24 m-auto mt-8 mb-5" alt="Logo" />
      <nav className="p-4 text-center">
        <ul>
          {items
            .filter((i) => !i.role || i.role.includes(props.role))
            .map((item, ind) => {
              return (
                <li key={`${item}-${ind}`} className="mb-2 p-1 hover:text-white font-normal hover:bg-secondary">
                  <div className="flex flex-row">
                    <item.Icon />
                    <Link to={item.link} className="p-1 w-9/12 block h-10 text-base hover:text-blue-500">
                      {item.label}
                    </Link>
                  </div>
                </li>
              );
            })}

          {/* Add more sidebar items here */}
        </ul>
      </nav>
    </div>
  );
}
