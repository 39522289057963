import { DELIVERY_COSTS } from '../constants/delivery-cost.constant';

const sortedDeliveryCosts = DELIVERY_COSTS.sort((a, b) => {
  const maxPostcodeLengthA = Math.max(...a.Postcode.split(',').map((pc) => pc.trim().length));
  const maxPostcodeLengthB = Math.max(...b.Postcode.split(',').map((pc) => pc.trim().length));
  return maxPostcodeLengthB - maxPostcodeLengthA;
});

// Preprocess the sorted DELIVERY_COSTS to a map for efficient lookups
const preprocessedDeliveryCosts = new Map<string, number>();

sortedDeliveryCosts.forEach((costObj) => {
  const standardizedPostCode = costObj.Postcode.trim().replace(/\s+/g, '').toUpperCase();
  if (!preprocessedDeliveryCosts.has(standardizedPostCode)) {
    // Avoid overwriting more specific entries
    preprocessedDeliveryCosts.set(standardizedPostCode, costObj.Price);
  }
});

export const getDeliveryCost = (deliveryAddressPostCode: string): number | undefined => {
  // Standardize the input postcode
  const standardizedInputPostCode = deliveryAddressPostCode.trim().replace(/\s+/g, '').toUpperCase();

  // Attempt to find the most specific matching delivery cost
  for (const [postcode, price] of preprocessedDeliveryCosts) {
    if (standardizedInputPostCode.startsWith(postcode)) {
      return price;
    }
  }

  // Return undefined or a default value if no match is found
  return undefined;
};
// TEST
// for (const p of DELIVERY_COSTS) {
//   const received = getDeliveryCost(p.Postcode);
//   if (received !== p.Price) {
//     console.log(p.Postcode, received, '====wrong', p.Price, '===right');
//   } else {
//     console.log('correct', received, p.Price);
//   }
// }
