export const ITEMS_DESCRIPTION = [
  'Collection item',
  'Candle',
  'Balloons',
  'Other',
  'Plant',
  'Hand-tied Bouquet IN water',
  'Hand-tied Bouquet NO water',
  'NSFC Vase/Container',
  'Client’s Vase/Container',
  'Petite Couture Arrangement',
  'Table arrangement',
];
