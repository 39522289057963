import React, { ReactNode } from 'react';
import { useGetUserQuery } from '../../store/user/user.api';
import { ERole } from '../../types/enums/roles.enum';
import Sidebar from '../sidebar/Sidebar';
import TopNavbar from './Appbar';

const Layout: React.FC<{ children: ReactNode; isAuth: boolean; setAuth: (val: boolean) => void }> = ({
  children,
  isAuth,
  setAuth,
}) => {
  if (!isAuth) return children;

  const { data } = useGetUserQuery({}, { refetchOnMountOrArgChange: true });

  return (
    <div className="grid grid-cols-5">
      <Sidebar role={data?.user?.role ?? ERole.MEMBER} />
      <div className="col-span-4 bg-white p-4">
        <TopNavbar user={data?.user} logout={() => setAuth(false)} />
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
