import { useEffect, useState } from 'react';
import { IUser } from '../../interfaces/user.interface';
import { useCreateUserAndSendInvitationMutation } from '../../store/auth/auth.api';
import { useDeleteUserMutation, useUpdateUserMutation } from '../../store/user/user.api';
import { ERole } from '../../types/enums/roles.enum';
import { EStores } from '../../types/enums/taken-by.enum';
import Cookies from 'js-cookie';
import { TOperations } from '../../types/operations.type';

export const useMember = (isMemberModalOpen: (isOpen: boolean) => void, data?: IUser) => {
  const [email, setEmail] = useState<string>();
  const [role, setRole] = useState<ERole>();
  const [firstName, setFirstName] = useState<string>();
  const [confirmationDialogOperation, setConfirmDialogOperation] = useState<TOperations | undefined>();
  const [store, setStore] = useState<EStores>();
  const [lastName, setLastName] = useState<string>();
  const [updateUser, { data: updatedUser }] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [createUser] = useCreateUserAndSendInvitationMutation();
  const handleUpdate = async () => {
    if (data?._id) {
      await updateUser({
        firstName,
        lastName,
        email,
        role,
        id: data._id,
        store,
      });
    }
    isMemberModalOpen(false);
  };
  const handleAddUser = async () => {
    if (email && firstName && lastName && store)
      await createUser({ email, firstName, lastName, role: role ?? ERole.ADMIN, store });
    isMemberModalOpen(false);
  };
  const handleDeleteUser = async () => {
    if (data?._id) {
      await deleteUser(data._id);
    }
    isMemberModalOpen(false);
  };
  useEffect(() => {
    if (data) {
      setEmail(data.email);
      setRole(data.role);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setStore(data.store);
    }
  }, [data]);
  useEffect(() => {
    if (updatedUser) {
      if (updatedUser.tokens?.accessToken) Cookies.set('access_token', updatedUser.tokens.accessToken);
      isMemberModalOpen(false);
    }
  }, [updatedUser]);
  return {
    email,
    role,
    firstName,
    lastName,
    setFirstName,
    setLastName,
    setEmail,
    setRole,
    handleUpdate,
    handleAddUser,
    handleDeleteUser,
    store,
    setStore,
    confirmationDialogOperation,
    setConfirmDialogOperation,
  };
};
