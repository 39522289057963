import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { ListNotificationsResponseDto, TListNotificationsQueryDto } from './dto/list.dto';
import { IMarkAsReadBody } from './dto/update.dto';

export const notificationsApi = createApi({
  reducerPath: 'notifications',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL || '/api',
    headers: {
      'x-api-key': 'DhKY3vDqDp8!ym$7jUD@K4PXu7PPkd',
    },
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${Cookies.get('access_token')}`);
      return headers;
    },
  }),
  tagTypes: ['notification', 'notifications'],
  endpoints: (builder) => ({
    getNotificationsList: builder.query<ListNotificationsResponseDto, TListNotificationsQueryDto>({
      query: (filters) => ({
        url: '/notifications',
        params: filters,
      }),
      providesTags: ['notifications'],
    }),
    markNotificationsAsRead: builder.mutation<void, IMarkAsReadBody>({
      query: (body) => ({
        url: '/notifications/mark-as-read',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['notifications'],
    }),
    removeNotification: builder.mutation<void, string>({
      query: (id) => ({
        url: `/notifications/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['notifications'],
    }),
  }),
});
export const { useGetNotificationsListQuery, useMarkNotificationsAsReadMutation, useRemoveNotificationMutation } =
  notificationsApi;
