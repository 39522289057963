import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetTokenQuery, useResetPasswordMutation } from '../../store/password/password.api';

export const useResetPassword = () => {
  const [majorErrors, setMajorErrors] = useState<string[]>([]);
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate('/');
  };
  if (!token) navigateToHome;
  const { data, isLoading: isFetchingTokenData, error } = useGetTokenQuery({ id: token ?? '' });
  useEffect(() => {
    if ((error as { status: number })?.status === 404)
      setMajorErrors(['There is no request found for resetting your password']);
    else if (error) setMajorErrors(['There was a problem initiating your password reset request']);
  }, [error]);
  const [resetPassword, { isLoading: isResetting, isSuccess: isResetSuccess }] = useResetPasswordMutation();
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>();
  const [minorErrors, setMinorErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(isFetchingTokenData || isResetting);
  }, [isFetchingTokenData, isResetting]);
  const onSubmit = async () => {
    const validationErrors = [];
    if (newPassword !== confirmNewPassword)
      // eslint-disable-next-line quotes
      validationErrors.push(`Password entered and confirmed password don't match`);
    if (!newPassword) validationErrors.push('Please provide password');
    if (validationErrors.length) setMinorErrors(validationErrors);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    else await resetPassword({ tokenId: data?.token._id ?? '', newPassword: newPassword! });
  };
  return {
    newPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    setNewPassword,
    onSubmit,
    isLoading,
    data,
    isResetSuccess,
    minorErrors,
    majorErrors,
    navigateToHome,
  };
};
