import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { IOrder } from '../../interfaces/order.interface';
import { ListOrdersResponseDto, TListOrdersQueryDto } from './dto/list.dto';
import { TUpdateOrderDto } from './dto/update.dto';

export const orderApi = createApi({
  reducerPath: 'order',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL || '/api',
    headers: {
      'x-api-key': 'DhKY3vDqDp8!ym$7jUD@K4PXu7PPkd',
    },
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${Cookies.get('access_token')}`);
      return headers;
    },
  }),
  tagTypes: ['order', 'orders'],
  endpoints: (builder) => ({
    getOrdersList: builder.query<ListOrdersResponseDto, TListOrdersQueryDto>({
      query: (filters) => ({
        url: 'order/list',
        params: filters,
      }),
      providesTags: ['orders'],
    }),
    createOrder: builder.mutation<IOrder, Omit<IOrder, '_id'>>({
      query: (createDto) => ({
        url: 'order',
        method: 'POST',
        body: createDto,
      }),
      invalidatesTags: [{ type: 'orders' }],
    }),
    updateOrder: builder.mutation<IOrder, TUpdateOrderDto>({
      query: ({ id, ...updateDto }) => ({
        url: `order/${id}`,
        method: 'PATCH',
        body: updateDto,
      }),
      invalidatesTags: (result) => [{ type: 'order', id: result?._id }, { type: 'orders' }],
    }),
    getOrder: builder.query<IOrder, string>({
      query: (id) => ({
        url: `order/${id}`,
      }),
      providesTags: (result) => [{ type: 'order', id: result?._id }],
    }),
  }),
});
export const { useGetOrdersListQuery, useCreateOrderMutation, useUpdateOrderMutation, useGetOrderQuery } = orderApi;
