import { InputAdornment, TextField, TextareaAutosize } from '@mui/material';
import * as React from 'react';
import { IAddress } from '../../interfaces/address.interface';
import { buildAddress, getAddressDetails, searchAddress } from '../../utils/address.utils';
import { FloristDropdown } from './Dropdown';

export interface IAddressFieldProps {
  address: IAddress | undefined;
  disabled?: boolean;
  onChange?: (address: IAddress | undefined) => void;
  onReset: () => void;
  label: string;
  selectId: string;
  selectLabel: string;
  error?: string;
  showNotesField?: boolean;
}

export function FloristAddressField(props: IAddressFieldProps) {
  const [postCode, setPostCode] = React.useState<string>();
  const [suggestions, setSuggestions] = React.useState<{ address: string; id: string }[]>();
  const handleSearch = async () => {
    const response = postCode ? await searchAddress(postCode) : [];
    setSuggestions(response);
  };

  const handleSelectAddress = async (id: string) => {
    const response = await getAddressDetails(id);
    if (response) {
      props.onChange?.({
        city: response.city,
        postCode: response.postcode,
        streetAddress: response.streetAddress,
        state: response.state,
      });
    }
  };

  return (
    <div className="grid grid-rows-2">
      {props.address ? (
        <>
          <TextField
            className="row-span-1"
            fullWidth
            label={props.label}
            disabled={props.disabled}
            value={buildAddress(props.address)}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <button
                    onClick={() => {
                      props.onReset();
                      setSuggestions(undefined);
                    }}
                    disabled={props.disabled}
                  >
                    Reset
                  </button>
                </InputAdornment>
              ),
            }}
          />
          {props.error && <p className="text-error row-span-1">{`*${props.error}`}</p>}
          {props.showNotesField && (
            <div>
              <h5 className="text-xs">Delivery Notes</h5>
              <TextareaAutosize
                value={props.address.note ?? ''}
                onChange={(e) => {
                  props.address && props.onChange?.({ ...props.address, note: e.target.value });
                }}
                className="border text-base border-secondary w-full !h-10 p-2"
              />
            </div>
          )}
        </>
      ) : (
        <>
          <TextField
            className="row-span-1"
            fullWidth
            label={'Post Code'}
            disabled={props.disabled}
            value={postCode ?? ''}
            onChange={(e) => setPostCode(e.target.value)}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <button onClick={handleSearch}>Search</button>
                </InputAdornment>
              ),
            }}
          />
          {suggestions && (
            <div className="row-span-1">
              <FloristDropdown
                options={suggestions.map((s) => ({ label: s.address, value: s.id }))}
                onChange={(id) => handleSelectAddress(id as string)}
                selectId={props.selectId}
                selectLabel={props.selectLabel}
                key={`address-search-${props.selectLabel}`}
              />
            </div>
          )}
          {props.error && <p className="text-error row-span-1">{`*${props.error}`}</p>}
        </>
      )}
    </div>
  );
}
