import * as React from 'react';
import { FloristButton } from '../../components/base/Button';
import { FloristInput } from '../../components/base/Input';
import { FloristSmallHeading } from '../../components/base/Small-Heading';
import { useLogin } from './useLogin';
import { FloristLoader } from '../../components/base/Loader';
import LogoSVG from '../../assets/logo/logo.svg';

export function Login() {
  const { email, onSubmit, password, setEmail, setPassword, isLoading, onForgotPassword } = useLogin();
  return (
    <div className="min-h-screen flex items-center justify-center ">
      <div className="w-1/2 h-1/2 bg-white p-8 rounded shadow-md">
        <div className="mb-10 text-center">
          <img src={LogoSVG} className="w-28 m-auto mt-8 mb-5" alt="Logo" />
          <FloristSmallHeading className="font-medium" label="Welcome Back!" />
        </div>
        {isLoading ? (
          <FloristLoader />
        ) : (
          <>
            <FloristInput
              label="Email Address"
              id="email"
              value={email}
              placeholder="Email Address"
              type={'text'}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FloristInput
              label="Password"
              id="password"
              value={password}
              placeholder="Password"
              type={'password'}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="mb-6">
              <FloristButton id="password" label="LOGIN" type="button" onClick={onSubmit} />
              <a className="text-xs hover:cursor-pointer hover:underline" onClick={onForgotPassword}>
                Forgot Password ?
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
