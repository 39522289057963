import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useViewOrder } from './useViewOrder';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { IconButton } from '@mui/material';
import LogoSVG from '../../assets/logo/logo.svg';
import { ETimes } from '../../types/enums/time.enum';
import { FloristButton } from '../../components/base/Button';
import { EOrderMethod } from '../../types/enums/order-method.enum';
import { EOrderStatus } from '../../types/enums/status.enum';
import { buildAddress } from '../../utils/address.utils';
import { ConfirmationDialog } from '../../components/base/ConfirmDialog';
import { mapItemToString } from '../../utils/item-order.util';
import { formatAmount } from '../../utils/amount.util';

export function ViewOrder() {
  const { id } = useParams<{ id: string }>();
  if (!id) {
    const navigate = useNavigate();
    navigate('/');
    return <></>;
  }
  const states = useViewOrder(id);

  return (
    <div className="flex flex-col" id="order-details">
      <img src={LogoSVG} className="w-28 m-auto mt-1 mb-1" alt="Logo" />
      {states.isDownloadingPdf ? null : (
        <div className="text-right">
          <IconButton
            className="right-0 w-2/12"
            onClick={() => {
              states.setIsDownloadingPdf(true);
            }}
          >
            <PictureAsPdfIcon />
          </IconButton>
        </div>
      )}

      <div className={`w-full p-4 border-collapse border border-secondary mb-4 ${states.orderStatus === EOrderStatus.CANCELLED ? 'line-through' : ''}`}>
        <h5 className="text-lg tracking-wide text-secondary-heading mb-4">Order Details</h5>
        <div className="grid grid-cols-3 gap-2 text-center grid-rows-1 w-full">
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Order Date</h5>
            <div className="flex flex-row text-sm justify-center">
              <CalendarMonthIcon className="!h-3" />
              <p>{states.orderDate.format('DD-MM-YYYY')}</p>
            </div>
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Fulfilled At</h5>
            <p className="text-sm text-light">{states.fulfilledAt?.toString?.().toUpperCase?.()}</p>
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Order Taken By</h5>
            <p className="text-sm text-light">{states.orderTakenBy?.toString?.().toUpperCase?.()}</p>
          </div>
        </div>
      </div>

      <div className={`w-full p-4 border-collapse border border-secondary mb-4 ${states.orderStatus === EOrderStatus.CANCELLED ? 'line-through' : ''}`}>
        <h5 className="text-lg tracking-wide text-secondary-heading mb-4">Customer Details</h5>
        <div className="grid grid-cols-4 grid-rows-1 text-center w-full gap-2">
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Customer Name</h5>
            <p className="text-sm text-light">{states.customerFirstName || states.customerLastName ? `${states.customerFirstName} ${states.customerLastName}` : 'Not available'}</p>
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Billing Address</h5>
            <p className="text-sm text-light">{buildAddress(states.billingAddress)}</p>
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Tel Number</h5>
            <p className="text-sm text-light">{states.customerPhone || 'Not Available'}</p>
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Email Address</h5>
            <p className="text-sm text-light">{states.customerEmail || 'Not Available'}</p>
          </div>
        </div>
      </div>

      <div className={`w-full p-4 border-collapse border border-secondary mb-4 ${states.orderStatus === EOrderStatus.CANCELLED ? 'line-through' : ''}`}>
        <h5 className="text-lg capitalize tracking-wide text-secondary-heading mb-4">{`${states.orderMethod} Details`}</h5>
        <div className="grid grid-cols-3 gap-2 text-center w-full">
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Method</h5>
            <p className="text-sm text-light capitalize">{states.orderMethod}</p>
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">{'Recipient\'s Name'}</h5>
            <p className="text-sm text-light">{`${states.recipientFirstName || ''} ${states.recipientLastName || ''}`}</p>
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">{'Recipient\'s Contact'}</h5>
            <p className="text-sm text-light">{states.recipientContact}</p>
          </div>
          {states.orderMethod === EOrderMethod.DELIVERY && (
            <>
              <div className="col-span-1 bg-gray-200">
                <>
                  <h5 className="text-base text-secondary tracking-wide">Delivery Address</h5>
                  <p className="text-sm text-light">{buildAddress(states.deliveryAddress)}</p>
                </>
              </div>
              {
                states.deliveryAddress?.note &&
                <div className="col-span-1 bg-gray-200">
                  <>
                    <h5 className="text-base text-secondary tracking-wide">Delivery Notes</h5>
                    <p className="text-sm text-light">{states.deliveryAddress?.note}</p>
                  </>
                </div>}
            </>
          )}
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">
              {states.orderMethod === EOrderMethod.DELIVERY ? 'Delivery Date And Time' : 'Collection Date And Time'}
            </h5>
            <p className="text-sm text-light">{`${states.fulfillmentDate.format('DD-MM-yyyy')} (${states.fulfillmentTime || ETimes.NOT_SPECIFIED
              })`}</p>
          </div>
        </div>
      </div>
      <div className={`w-full p-4 border-collapse border border-secondary mb-4 ${states.orderStatus === EOrderStatus.CANCELLED ? 'line-through' : ''}`}>
        <h5 className="text-lg tracking-wide text-secondary-heading mb-4">Order Details</h5>
        <div className="grid grid-cols-6 gap-2  w-full">
          <div className="col-span-2 row-span-2 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Card Message</h5>
            <p className="text-sm text-light">{states.cardMessage}</p>
          </div>
          <div className="col-span-2 row-span-2 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Content</h5>
            <p className="text-sm text-light">{states.content}</p>
          </div>
          <div className="col-span-2 row-span-2 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Item Description</h5>
            <p className="text-sm text-light">{states.itemDescription?.map(mapItemToString).join(', ')}</p>
          </div>
          <div className="col-span-2 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Payment Status</h5>
            <p className="text-sm text-light">{states.paymentStatus}</p>
          </div>
          <div className="col-span-2">
            <h5 className="text-base text-secondary tracking-wide">Fulfillment Status</h5>
            <p className="text-sm text-light">{states.fulfillmentStatus ?? 'N/A'}</p>
          </div>
          <div className="col-span-2"></div>
          <div className="col-span-2 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Delivery Cost</h5>
            <p className="text-sm text-light">{states.deliveryCost ? formatAmount(states.deliveryCost) : ''}</p>
          </div>
          <div className="col-span-2 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Item Price</h5>
            <p className="text-sm text-light">{states.itemPrice ? formatAmount(states.itemPrice) : ''}</p>
          </div>
          <div className="col-span-2 bg-gray-200">
            <h5 className="text-base text-secondary tracking-wide">Total Cost</h5>
            <p className="text-sm text-light">{states.costSummary ? formatAmount(states.costSummary) : ''}</p>
          </div>
        </div>
      </div>

      {states.isDownloadingPdf ? null : (
        <div className="mx-4 grid grid-cols-4 gap-x-2 grid-rows-2 mb-4">
          <div className="col-span-1 row-span-2">{
            states.orderStatus !== EOrderStatus.DELETED &&
            <FloristButton
              label={states.orderStatus === EOrderStatus.ACTIVE ? 'Mark as Cancelled' : 'Mark as Active'}
              onClick={() => {
                states.onStatusChange(id, states.orderStatus === EOrderStatus.ACTIVE ? EOrderStatus.CANCELLED : EOrderStatus.ACTIVE);
              }}
              className="bg-error"
            />
          }
          </div>
          <div className="col-span-2 row-span-2"></div>
          <FloristButton
            label={states.orderStatus === EOrderStatus.DELETED ? 'Restore Order' : 'Delete Order'}
            onClick={() => {
              states.setIsConfirmDialogOpen(true);
            }}
            className={`col-span-1 row-span-2 ${states.orderStatus === EOrderStatus.DELETED ? '!bg-green' : '!bg-error'}`}
          />
          <ConfirmationDialog
            isOpen={states.isConfirmationDialogOpen}
            title={`Confirm ${states.orderStatus === EOrderStatus.DELETED ? 'Restore' : 'Delete'} `}
            content={`Are you sure you want to ${states.orderStatus === EOrderStatus.DELETED ? 'restore' : 'delete'} this order?`}
            onConfirm={() => states.onDeleteOrRestore(id)}
            onCancel={() => states.setIsConfirmDialogOpen(false)}
          />
        </div>
      )}
    </div>
  );
}
