import * as React from 'react';
import LogoSVG from '../../assets/logo/logo.svg';
import { FloristButton } from '../../components/base/Button';
import { FloristInput } from '../../components/base/Input';
import { FloristLoader } from '../../components/base/Loader';
import { FloristSmallHeading } from '../../components/base/Small-Heading';
import { useVerifyAccount } from './useVerifyAccount';

export function VerifyAccount() {
  const {
    confirmNewPassword,
    data,
    isLoading,
    majorErrors,
    isSetSuccess,
    minorErrors,
    navigateToHome,
    newPassword,
    onSubmit,
    setConfirmNewPassword,
    setNewPassword,
  } = useVerifyAccount();
  return (
    <div className="min-h-screen flex items-center justify-center ">
      <div className="w-1/2 h-1/2 bg-white p-8 rounded shadow-md">
        <div className="mb-10 text-center">
          <img src={LogoSVG} className="w-28 m-auto mt-8 mb-5" alt="Logo" />
          <FloristSmallHeading
            additionalClassNames={['font-medium text-sm']}
            label={`Complete Account Setup Process By Providing Your Password For Your New Account ${data?.user.email ? `Linked With ${data?.user.email}` : ''
              }`}
          />
        </div>
        {isLoading ? (
          <FloristLoader />
        ) : majorErrors.length ? (
          <>
            {majorErrors.map((e) => (
              <p key={e} className="text-md">
                {e}
              </p>
            ))}
            <div className="mb-6">
              <FloristButton
                id="navigate-home"
                label="Return to home"
                type="button"
                onClick={navigateToHome}
              />
            </div>
          </>
        ) : (
          <>
            <FloristInput
              label="New Password"
              id="set-new-pass"
              value={newPassword}
              placeholder="New Password"
              type={'password'}
              onChange={(e) => setNewPassword(e.target.value)}
              disabled={!!isSetSuccess}
            />
            <FloristInput
              label="Confirm New Password"
              id="set-confirm-pass"
              value={confirmNewPassword}
              placeholder="Confirm New Password"
              type={'password'}
              disabled={!!isSetSuccess}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            {!isSetSuccess ? (
              <div className="mb-6">
                <FloristButton
                  id="set-password"
                  label="SUBMIT"
                  type="button"
                  onClick={onSubmit}
                />
                {minorErrors?.map((e) => (
                  <p key={`${e}`} className="text-sm text-error">
                    {e}
                  </p>
                ))}
              </div>
            ) : (
              <>
                {' '}
                <p className="text-green">
                  Your account has been created. Please click below to login to your account.
                </p>
                <FloristButton
                  id="navigate-home-success"
                  label="Return To Home"
                  type="button"
                  onClick={navigateToHome}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
