import React from 'react';
import { useLocation } from 'react-router-dom';
import { IUser } from '../../interfaces/user.interface';
import ProfileDropDown from '../appbar/ProfileDropDown';
import { concatName } from '../../utils/name.util';
import NotificationDropDown from '../notification/NotificationDropDown';

interface IProps {
  user?: IUser;
  logout: () => void;
}
const TopNavbar: React.FC<IProps> = (props) => {
  const location = useLocation();

  return (
    <div className="mb-3 flex flex-row justify-between p-2 border-b border-gray-light">
      <h1 className="capitalize text-lg">{location.pathname.split('/')[1]?.replaceAll('-', ' ') ?? ''}</h1>
      <div className="flex flex-row">
        <div className="flex flex-col">
          <h1 className="text-sm">{concatName(props.user?.firstName, props.user?.lastName)}</h1>
          <p className="text-xs">{props.user?.email}</p>
        </div>
        <ProfileDropDown logout={props.logout} />
        <NotificationDropDown />
      </div>
    </div>
  );
};

export default TopNavbar;
