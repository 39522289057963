import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { rtkQueryErrorLogger } from '../components/base/Error-Toaster';
import { authApi } from './auth/auth.api';
import { orderApi } from './order/order.api';
import { passwordApi } from './password/password.api';
import { userApi } from './user/user.api';
import { notificationsApi } from './notification/notification.api';

const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [passwordApi.reducerPath]: passwordApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userApi.middleware,
      authApi.middleware,
      orderApi.middleware,
      passwordApi.middleware,
      notificationsApi.middleware,
      rtkQueryErrorLogger,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
