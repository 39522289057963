import React from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useGetUserQuery } from '../store/user/user.api';
import { FloristLoader } from './base/Loader';

interface IProps extends Record<string, unknown> {
  isAuth: boolean;
  setAuth: (val: boolean) => void;
}

const ProtectedRoute = (props: IProps) => {
  const navigate = useNavigate();
  const accessToken = Cookies.get('access_token');
  const { error, data } = useGetUserQuery({}, { refetchOnMountOrArgChange: true });
  if (!accessToken || error) {
    if (props.isAuth) props.setAuth(false);
    // Redirect to '/' if there's no access token or if there's a 401 error
    navigate('/');
    return null;
  }
  if (accessToken && !error && data) {
    props.setAuth(true);
    return <Outlet />;
  }
  return error ? <Navigate to="/" /> : <FloristLoader />;
};

export default ProtectedRoute;
