import { useState } from 'react';
import { useForgotPasswordRequestMutation } from '../../store/auth/auth.api';

export const useForgotPassword = () => {
  const [requestForgotPasswordEmail, { isLoading, isError, isSuccess }] = useForgotPasswordRequestMutation();
  const [email, setEmail] = useState<string>();
  const onSubmit = async () => {
    if (email) await requestForgotPasswordEmail({ email });
  };
  return {
    email,
    setEmail,
    onSubmit,
    isLoading: isLoading,
    isError,
    isSuccess,
  };
};
