import React from 'react'
import { FloristTextField } from './base/TextField'
import { FloristDropdown } from './base/Dropdown'
import { EOrderMethod } from '../types/enums/order-method.enum'
import { EStatus } from '../types/enums/payment-status.enum'
import { FloristDateRangePicker } from './base/DateRange'
import moment from 'moment'
import { ETimes } from '../types/enums/time.enum'
import { EStores } from '../types/enums/taken-by.enum'
import { ERole } from '../types/enums/roles.enum'
import { EFulfillmentStatus } from '../types/enums/fulfillment-status.enum'
import { IUser } from '../interfaces/user.interface'
import { FloristButton } from './base/Button'
import { IFilters } from '../interfaces/order-filters.interface'


interface Props {
    setFilters: (filters: IFilters) => void
    filters: IFilters;
    currentUser: IUser | undefined
}
export default function AllOrderFilters({ setFilters, filters, currentUser }: Props) {
    return (
        <div>
            <div className="grid grid-rows-2 grid-cols-4 gap-x-3 gap-y-2">
                <FloristTextField
                    size="small"
                    type='search'
                    label={'Search by name, email, phone or post code'}
                    value={filters.search || ''}
                    onChange={(e) => setFilters({ ...filters, 'search': e || undefined })}
                    InputLabelProps={{
                        style: {
                            fontSize: '0.8em',
                        }
                    }}
                />
                <FloristTextField
                    size="small"
                    label={'Post Code'}
                    value={filters['post-code'] || ''}
                    onChange={(e) => setFilters({ ...filters, 'post-code': e || undefined })}
                />
                <FloristDropdown
                    selectLabel="Method"
                    onChange={(e) => setFilters({ ...filters, method: (e as EOrderMethod) || undefined })}
                    options={Object.values(EOrderMethod).map((m) => ({ label: m.toUpperCase(), value: m }))}
                    selectId={'method-filter-select'}
                    key={'method-filter-select-key'}
                    value={filters.method}
                />
                <FloristDropdown
                    selectLabel="Payment Status"
                    onChange={(e) => setFilters({ ...filters, status: (e as EStatus) || undefined })}
                    options={Object.values(EStatus).map((m) => ({ label: m.toUpperCase(), value: m }))}
                    selectId={'status-filter-select'}
                    key={'status-filter-select-key'}
                    value={filters.status}
                />
                <FloristDateRangePicker
                    onChange={(e) =>
                        setFilters({
                            ...filters,
                            'fulfillment-date-start': e?.[0]?.toDate(),
                            'fulfillment-date-end': e?.[1]?.toDate(),
                        })
                    }
                    label={'Fulfillment Date'}
                    value={
                        filters['fulfillment-date-start']
                            ? [moment(filters['fulfillment-date-start']), moment(filters['fulfillment-date-end'])]
                            : undefined
                    }
                />
                <FloristDropdown
                    selectLabel="Fulfillment time"
                    onChange={(e) => setFilters({ ...filters, 'fulfillment-time': (e as ETimes) || undefined })}
                    options={Object.values(ETimes).map((m) => ({ label: m.toUpperCase(), value: m }))}
                    selectId={'fulfill-time-filter-select'}
                    key={'fulfill-time-filter-select-key'}
                    value={filters['fulfillment-time']}
                />
                <FloristDropdown
                    selectLabel="Fulfilled At"
                    onChange={(e) => setFilters({ ...filters, 'fulfilled-at': (e as EStores) || undefined })}
                    options={Object.values(EStores).map((m) => ({ label: m.toUpperCase(), value: m }))}
                    selectId={'fulfilled-at-filter-select'}
                    key={'fulfilled-at-filter-select-key'}
                    value={currentUser?.role !== ERole.ADMIN ? currentUser?.store : filters['fulfilled-at']}
                    disabled={currentUser?.role !== ERole.ADMIN}
                />
                <FloristDropdown
                    selectLabel="Fulfilled Status"
                    onChange={(e) => setFilters({ ...filters, 'fulfilled-status': (e as EFulfillmentStatus) || undefined })}
                    options={Object.values(EFulfillmentStatus).map((m) => ({ label: m.toUpperCase(), value: m }))}
                    selectId={'fulfilled-status-filter-select'}
                    key={'fulfilled-status-filter-select-key'}
                    value={filters['fulfilled-status']}
                />
            </div>
            <FloristButton className='p-1 m-1 float-right !w-60' onClick={() => setFilters({})} label="Clear Filters" />
        </div>
    )
}
