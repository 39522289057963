import { toast } from 'react-toastify';
import { isRejectedWithValue, MiddlewareAPI, Middleware } from '@reduxjs/toolkit';

/**
 * Log a warning and show a toast!
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const rtkQueryErrorLogger: Middleware = (_api: MiddlewareAPI) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    toast.error(action?.payload?.data?.message || 'There was an error', {
      autoClose: 1000,
      position: 'bottom-center',
      type: 'error',
    });
  }

  return next(action);
};
