import Cookies from 'js-cookie';
import { useGetUserQuery, useUpdateUserMutation } from '../../store/user/user.api';
import { ERole } from '../../types/enums/roles.enum';
import { useEffect, useState } from 'react';

export const useUserSettings = () => {
  const { data } = useGetUserQuery({}, { refetchOnMountOrArgChange: true });
  const [isEditAble, setEditAble] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [role, setRole] = useState<ERole>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [updateUser, { data: updatedUser }] = useUpdateUserMutation();
  const handleUpdate = async () => {
    if (data?.user?._id)
      await updateUser({
        firstName,
        lastName,
        email,
        id: data.user?._id,
      });
  };
  useEffect(() => {
    if (data) {
      setEmail(data.user?.email);
      setRole(data.user?.role);
      setFirstName(data.user?.firstName);
      setLastName(data.user?.lastName);
    }
  }, [data]);
  useEffect(() => {
    if (updatedUser) {
      if (updatedUser.tokens?.accessToken) Cookies.set('access_token', updatedUser.tokens.accessToken);
      setEditAble(false);
    }
  }, [updatedUser]);
  return {
    isEditAble,
    setEditAble,
    email,
    role,
    firstName,
    lastName,
    setFirstName,
    setLastName,
    setEmail,
    handleUpdate,
  };
};
