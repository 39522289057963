import React from 'react';
import { INotification } from '../../interfaces/notification.interface';
import { NotificationItem } from './NotificationItem';
import { Collapse, List } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';


interface NotificationListProps {
    notifications: INotification[],
    onNotificationItemClick: (id: string) => void,
    onNotificationRemove: (id: string) => void
}
export const NotificationList = ({ notifications, onNotificationItemClick, onNotificationRemove }: NotificationListProps) => {
    return (
        <div>
            <List sx={{ mt: 1 }}>
                <TransitionGroup>
                    {notifications.map((notification) => (
                        <Collapse key={notification._id}>{
                            <NotificationItem
                                key={notification.subjectId}
                                notification={notification}
                                onNotificationItemClick={onNotificationItemClick}
                                onNotificationRemove={onNotificationRemove}
                            />
                        }</Collapse>

                    ))}

                </TransitionGroup>
            </List>
        </div>
    );
};

