import * as React from 'react';

export interface IButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  additionalClassNames?: string[];
  label: string;
}

export function FloristButton({ className, label, onClick, ...extra }: IButtonProps) {
  return (
    <button {...extra} className={`bg-secondary w-full rounded-md text-sm md:text-base text-white font-thin ${className}`} onClick={onClick}>
      {label}
    </button>
  );
}
