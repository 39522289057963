export const DELIVERY_COSTS = [
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW1X',
    Price: 0,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW1W',
    Price: 0,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW1A',
    Price: 15,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW1E',
    Price: 15,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW1H',
    Price: 15,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW1P',
    Price: 15,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW2',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW3',
    Price: 0,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW4',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW5',
    Price: 15,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW6',
    Price: 15,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW7',
    Price: 15,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW8',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW9',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW10',
    Price: 15,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW11',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW12',
    Price: 20,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW13',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW14',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW15',
    Price: 30,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW16',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW17',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW18',
    Price: 30,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW19',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SW20',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'W1',
    Price: 15,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'W2',
    Price: 20,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'W3',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'W4',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'W5',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'W6',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'W7',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'W8',
    Price: 15,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'W9',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'W10',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'W11',
    Price: 20,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'W12',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'W13',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'W14',
    Price: 20,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'WC1',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'WC2',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N1',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N2',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N3',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N4',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N5',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N6',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N7',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N8',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N9',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N10',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N11',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N12',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N13',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N14',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N15',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N16',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N17',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N18',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N19',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N20',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N21',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'N22',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'NW1',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'NW2',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'NW3',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'NW4',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'NW5',
    Price: 30,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'NW6',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'NW7',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'NW8',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'NW9',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'NW10',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'NW11',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E1',
    Price: 30,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E2',
    Price: 30,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E3',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E4',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E5',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E6',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E7',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E8',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E9',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E10',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E11',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E12',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E13',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E14',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E15',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E16',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'E17',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'EC1',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'EC2',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'EC3',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'EC4',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE1',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE2',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE3',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE4',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE5',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE6',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE7',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE8',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE9',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE10',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE11',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE12',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE13',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE14',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE15',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE16',
    Price: 35,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE17',
    Price: 25,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE18',
    Price: 55,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE19',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE20',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE21',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE22',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE23',
    Price: 45,
  },
  {
    'Type of delivery': 'NSFC Van',
    Postcode: 'SE24',
    Price: 35,
  },
];
