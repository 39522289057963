import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { IUser } from '../../interfaces/user.interface';
import { IGetTokenQueryRequestDto, IGetTokenResponseDto, IResetPasswordRequestDto } from './dto/password.dto';

export const passwordApi = createApi({
  reducerPath: 'password',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL || '/api',
    headers: {
      'x-api-key': 'DhKY3vDqDp8!ym$7jUD@K4PXu7PPkd',
    },
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${Cookies.get('access_token')}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getToken: builder.query<IGetTokenResponseDto, IGetTokenQueryRequestDto>({
      query: (filters) => ({
        method: 'GET',
        url: `password/${filters.id}`,
      }),
    }),
    resetPassword: builder.mutation<IUser, IResetPasswordRequestDto>({
      query: (resetDto) => ({
        method: 'POST',
        url: 'password/reset',
        body: resetDto,
      }),
    }),
  }),
});
export const { useGetTokenQuery, useResetPasswordMutation } = passwordApi;
