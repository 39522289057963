import { useNavigate } from 'react-router-dom';
/* eslint-disable @typescript-eslint/no-empty-function */
import { IAddress } from './../../interfaces/address.interface';
import { EStatus } from '../../types/enums/payment-status.enum';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useGetOrderQuery, useUpdateOrderMutation } from '../../store/order/order.api';
import { EOrderMethod } from '../../types/enums/order-method.enum';
import { TErrors } from './Order';
import { EStores } from '../../types/enums/taken-by.enum';
import { EFulfillmentStatus } from '../../types/enums/fulfillment-status.enum';
import { getDeliveryCost } from '../../utils/delivery-cost.util';
import { IOrder } from '../../interfaces/order.interface';
import { ETimes } from '../../types/enums/time.enum';
import { useGetUserQuery } from '../../store/user/user.api';
import { IOrderItem } from '../../interfaces/item.interface';

export const useEditOrder = (id: string) => {
  const { data } = useGetOrderQuery(id);
  const [updateOrder] = useUpdateOrderMutation();
  const { data: currentUser } = useGetUserQuery({});
  const navigate = useNavigate();
  const [errors, setErrors] = useState<TErrors>({});
  const [isConfirmationDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setFulfilledAt(data?.fulfilledAt);
      setOrderTakenBy(data?.takenBy);
      data.orderedAt && setOrderDate(moment(data.orderedAt));
      setCustomerFirstName(data.customer?.firstName);
      setCustomerLastName(data.customer?.lastName);
      setBillingAddress(data.customer?.billingAddress);
      setCardMessage(data.card?.message);
      setContent(data.content);
      setCostSummary(data.costSummary);
      setCustomerEmail(data.customer?.email);
      setDeliveryAddress(data.deliveryAddress);
      setCustomerPhone(data.customer?.phone);
      setItemDescription(data.itemDescription || []);
      setOrderMethod(data.method);
      setPaymentStatus(data.status);
      setRecipientContact(data.recipientPhone);
      data.fulfillmentDate && setFulfillmentDate(moment(data.fulfillmentDate));
      setFulfillmentTime(data.fulfillmentTime);
      setRecipientFirstName(data.recipientFirstName);
      setRecipientLastName(data.recipientLastName);
      setItemPrice(data.itemPrice);
      setDeliveryCost(data.deliveryCost);
      setFulfillmentStatus(data.fulfillmentStatus);
      setIsShopifyOrder(data.takenBy === EStores.SHOPIFY);
    }
  }, [data]);
  const [customerFirstName, setCustomerFirstName] = useState<string>();
  const [customerLastName, setCustomerLastName] = useState<string>();
  const [customerPhone, setCustomerPhone] = useState<string>();
  const [customerEmail, setCustomerEmail] = useState<string>();
  const [billingAddress, setBillingAddress] = useState<IAddress>();
  const [fulfilledAt, setFulfilledAt] = useState<EStores>();
  const [orderTakenBy, setOrderTakenBy] = useState<IOrder['takenBy']>();
  const [orderDate, setOrderDate] = useState<Moment>(moment());
  const [orderMethod, setOrderMethod] = useState<EOrderMethod>();
  const [fulfillmentDate, setFulfillmentDate] = useState<Moment>(moment());
  const [fulfillmentTime, setFulfillmentTime] = useState<ETimes>();
  const [recipientFirstName, setRecipientFirstName] = useState<string>();
  const [recipientLastName, setRecipientLastName] = useState<string>();
  const [deliveryAddress, setDeliveryAddress] = useState<IAddress>();
  const [recipientContact, setRecipientContact] = useState<string>();
  const [deliveryCost, setDeliveryCost] = useState<number>();
  const [cardMessage, setCardMessage] = useState<string>();
  const [itemDescription, setItemDescription] = useState<IOrderItem[]>([]);
  const [paymentStatus, setPaymentStatus] = useState<EStatus>();
  const [itemPrice, setItemPrice] = useState<number>();
  const [costSummary, setCostSummary] = useState<number>();
  const [content, setContent] = useState<string>();
  const [fulfillmentStatus, setFulfillmentStatus] = useState<EFulfillmentStatus>();
  const [isShopifyOrder, setIsShopifyOrder] = useState<boolean>();

  const onSubmit = async () => {
    let errors: TErrors = {};
    // if (!customerFirstName) errors = { ...errors, customerFirstName: 'Is Required' };
    // if (!customerLastName) errors = { ...errors, customerLastName: 'Is Required' };
    // if (!customerEmail) errors = { ...errors, customerEmail: 'Is Required' };
    // if (!billingAddress) errors = { ...errors, billingAddress: 'Is Required' };
    if (typeof itemPrice !== 'number') errors = { ...errors, itemPrice: 'Is Required' };
    if (orderMethod === EOrderMethod.DELIVERY) {
      if (!recipientContact) {
        errors = { ...errors, recipientContact: 'Is Required' };
      }
      if (!recipientFirstName) {
        errors = { ...errors, recipientFirstName: 'Is Required' };
      }
      if (!recipientLastName) {
        errors = { ...errors, recipientLastName: 'Is Required' };
      }
      // Only harrods can edit the delivery cost manually
      if (typeof deliveryCost !== 'number' && fulfilledAt === EStores.HARRODS) {
        errors = { ...errors, deliveryCost: 'Is Required' };
      }
    }
    if (typeof costSummary !== 'number') errors = { ...errors, costSummary: 'Is Required' };
    if (orderMethod === EOrderMethod.DELIVERY && !deliveryAddress)
      errors = { ...errors, deliveryAddress: 'Is Required' };
    if (!fulfillmentDate) errors = { ...errors, fulfillmentDate: 'Is Required' };
    if (orderMethod === EOrderMethod.DELIVERY && !fulfillmentTime)
      errors = { ...errors, fulfillmentTime: 'Is Required' };
    if (!orderDate) errors = { ...errors, orderDate: 'Is Required' };
    if (!orderMethod) errors = { ...errors, orderMethod: 'Is Required' };
    if (!paymentStatus) errors = { ...errors, paymentStatus: 'Is Required' };
    if (!fulfilledAt) errors = { ...errors, fulfilledAt: 'Is Required' };
    if (!orderTakenBy) errors = { ...errors, orderTakenBy: 'Is Required' };
    if (!itemDescription?.length) errors = { ...errors, itemDescription: 'Is Required' };
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    if (data?._id) {
      {
        await updateOrder({
          card: cardMessage ? { message: cardMessage } : undefined,
          content,
          costSummary,
          fulfillmentDate: fulfillmentDate?.toDate(),
          fulfillmentTime,
          deliveryAddress: orderMethod === EOrderMethod.DELIVERY ? deliveryAddress : undefined,
          deliveryCost,
          itemDescription,
          method: orderMethod,
          recipientFirstName,
          recipientLastName,
          recipientPhone: recipientContact,
          status: paymentStatus,
          fulfilledAt: fulfilledAt,
          takenBy: orderTakenBy,
          id: data._id,
          customer: {
            billingAddress,
            email: customerEmail,
            firstName: customerFirstName,
            lastName: customerLastName,
            phone: customerPhone,
          },
          orderedAt: orderDate?.toDate(),
          itemPrice,
          fulfillmentStatus,
        });
        navigate('/all-orders');
      }
    }
  };

  const setDeliveryAddressAndDeliveryCost = (address?: IAddress) => {
    if (address && address.postCode) {
      const deliverCost = getDeliveryCost(address.postCode);
      if (typeof deliverCost !== 'number') setErrors({ ...errors, deliveryAddress: 'We do not deliver to this area.' });
      else {
        setDeliveryCost(getDeliveryCost(address.postCode));
      }
    } else {
      setErrors({ ...errors, deliveryAddress: undefined });
    }
    setDeliveryAddress(address);
  };
  const setOrderMethodAndCost = (method?: EOrderMethod) => {
    setOrderMethod(method);
    // If collection then there is no delivery fee
    if (method === EOrderMethod.COLLECTION) setDeliveryCost(0);
    else if (fulfilledAt !== EStores.HARRODS) {
      if (deliveryAddress?.postCode) {
        setDeliveryCost(getDeliveryCost(deliveryAddress.postCode));
      } else {
        setDeliveryCost(undefined);
      }
    }
  };
  const handleStoreChange = (newStore: EStores) => {
    if (newStore === EStores.HARRODS && !isShopifyOrder) setDeliveryCost(undefined);
    else if (deliveryAddress?.postCode) {
      setDeliveryCost(getDeliveryCost(deliveryAddress.postCode));
    }
    setFulfilledAt(newStore);
  };
  return {
    data,
    orderDate,
    fulfilledAt,
    orderTakenBy,
    customerFirstName,
    customerLastName,
    billingAddress,
    customerPhone,
    customerEmail,
    orderMethod,
    deliveryAddress,
    recipientContact,
    recipientFirstName,
    recipientLastName,
    costSummary,
    cardMessage,
    deliveryCost,
    itemDescription,
    paymentStatus,
    itemPrice,
    content,
    fulfillmentDate,
    fulfillmentTime,
    fulfillmentStatus,
    orderStatus: data?.orderStatus,
    setFulfillmentStatus,
    setOrderDate,
    handleStoreChange,
    setDeliveryCost,
    setOrderTakenBy,
    setCustomerFirstName,
    setCustomerLastName,
    setBillingAddress,
    setCustomerPhone,
    setCustomerEmail,
    setOrderMethod: setOrderMethodAndCost,
    setDeliveryAddressAndDeliveryCost,
    setRecipientContact,
    setRecipientFirstName,
    setRecipientLastName,
    setCostSummary,
    setCardMessage,
    setItemDescription,
    setPaymentStatus,
    setItemPrice,
    setContent,
    setFulfillmentDate,
    setFulfillmentTime,
    onSubmit,
    errors,
    currentUser: currentUser?.user,
    isShopifyOrder,
    isConfirmationDialogOpen,
    setIsConfirmDialogOpen,
  };
};
