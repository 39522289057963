import * as React from 'react';
import { FloristButton } from '../../components/base/Button';
import { FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material';
import { Moment } from 'moment';
import { FloristDatePicker } from '../../components/base/DatePicker';
import { FloristDropdown } from '../../components/base/Dropdown';
import { FloristPhoneField } from '../../components/base/PhoneField';
import { EOrderMethod } from '../../types/enums/order-method.enum';
import { EStatus } from '../../types/enums/payment-status.enum';
import { FloristAddressField } from '../../components/base/AddressField';
import { IAddress } from '../../interfaces/address.interface';
import { EStores } from '../../types/enums/taken-by.enum';
import { FloristTextField } from '../../components/base/TextField';
import { EFulfillmentStatus } from '../../types/enums/fulfillment-status.enum';
import { getUsers } from '../../constants/user.constant';
import { IOrder } from '../../interfaces/order.interface';
import { ETimes } from '../../types/enums/time.enum';
import { ITEMS_DESCRIPTION } from '../../constants/item-description.constant';
import { IUser } from '../../interfaces/user.interface';
import { ERole } from '../../types/enums/roles.enum';
import { EOrderStatus } from '../../types/enums/status.enum';
import { ConfirmationDialog } from '../../components/base/ConfirmDialog';
import { FloristDropdownWithQtyPicker } from '../../components/base/DropdownWithQtyPicker';
import { IOrderItem } from '../../interfaces/item.interface';

export interface IFields {
  orderDate: Moment;
  fulfilledAt?: EStores;
  orderTakenBy?: IOrder['takenBy'];
  customerFirstName?: string;
  customerLastName?: string;
  billingAddress?: IAddress;
  customerPhone?: string;
  customerEmail?: string;
  orderMethod?: EOrderMethod;
  deliveryAddress?: IAddress;
  recipientContact?: string;
  recipientFirstName?: string;
  recipientLastName?: string;
  costSummary?: number;
  cardMessage?: string;
  deliveryCost?: number;
  itemDescription?: IOrderItem[];
  paymentStatus?: EStatus;
  itemPrice?: number;
  content?: string;
  fulfillmentDate: Moment;
  fulfillmentTime?: ETimes;
  fulfillmentStatus?: EFulfillmentStatus;
  orderStatus?: EOrderStatus;
  isShopifyOrder?: boolean;
  isConfirmationDialogOpen?: boolean
}
export type TErrors = {
  [k in keyof IFields]?: string;
};
interface IActions {
  setOrderDate: React.Dispatch<React.SetStateAction<Moment>>;
  handleStoreChange: (newStore: EStores) => void;
  setOrderTakenBy: React.Dispatch<React.SetStateAction<IOrder['takenBy'] | undefined>>;
  setCustomerFirstName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCustomerLastName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setBillingAddress: React.Dispatch<React.SetStateAction<IAddress | undefined>>;
  setCustomerPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCustomerEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
  setOrderMethod: (method?: EOrderMethod) => void;
  setDeliveryAddressAndDeliveryCost: (address?: IAddress) => void;
  setRecipientContact: React.Dispatch<React.SetStateAction<string | undefined>>;
  setRecipientFirstName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setRecipientLastName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCostSummary: React.Dispatch<React.SetStateAction<number | undefined>>;
  setCardMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  setItemDescription: React.Dispatch<React.SetStateAction<IOrderItem[]>>;
  setPaymentStatus: React.Dispatch<React.SetStateAction<EStatus | undefined>>;
  setItemPrice: React.Dispatch<React.SetStateAction<number | undefined>>;
  setContent: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFulfillmentDate: React.Dispatch<React.SetStateAction<Moment>>;
  setFulfillmentTime: React.Dispatch<React.SetStateAction<ETimes | undefined>>;
  setDeliveryCost: React.Dispatch<React.SetStateAction<number | undefined>>;
  setFulfillmentStatus: React.Dispatch<React.SetStateAction<EFulfillmentStatus | undefined>>;
  setIsConfirmDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}
interface ICreateOrderProps extends Omit<IActions, 'setFulfillmentStatus'>, Omit<IFields, 'fulfillmentStatus'> {
  action: 'create';
  onSubmit: () => void;
  errors: TErrors;
  currentUser?: IUser;
}
interface IEditOrderProps extends IActions, IFields {
  action: 'edit';
  onSubmit: () => void;
  errors: TErrors;
  currentUser?: IUser;
}

export function OrderDetails(props: ICreateOrderProps | IEditOrderProps) {
  const isShopifyOrder = typeof props.isShopifyOrder === 'boolean' && props.isShopifyOrder; // props.action === 'view';
  const isCancelled = props.orderStatus === EOrderStatus.CANCELLED
  const isDisabled = isShopifyOrder || isCancelled
  return (
    <div className="flex flex-col">
      <div className="w-full p-4 border-collapse border border-secondary mb-2">
        <div className="grid grid-cols-3 gap-2 grid-rows-1 w-full">
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Order Date</h5>
            <FloristDatePicker
              disabled={isDisabled}
              label="Order Date"
              onChange={(nDate) => {
                nDate && !isShopifyOrder && props.setOrderDate?.(nDate);
              }}
              value={props.orderDate}
              error={props.errors.orderDate}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Fulfilled At</h5>
            <FloristDropdown
              onChange={(newVal) => {
                props.handleStoreChange?.(newVal as EStores);
              }}
              options={
                isShopifyOrder
                  ? Object.values(EStores).map((o) => ({ label: o.toUpperCase(), value: o }))
                  : Object.values(EStores)
                    .map((o) => ({ label: o.toUpperCase(), value: o }))
                    .filter((o) => o.value !== EStores.SHOPIFY)
              }
              selectId="edit-order-fulfilled-at"
              selectLabel="Fulfilled At"
              value={props.fulfilledAt}
              disabled={isCancelled || props.currentUser?.role !== ERole.ADMIN}
              key={'order-taken-at-store-edit-key'}
              error={props.errors.fulfilledAt}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Order Taken By</h5>
            <FloristDropdown
              disabled={isDisabled}
              onChange={(newVal) => {
                !isShopifyOrder && props.setOrderTakenBy?.(newVal as IOrder['takenBy']);
              }}
              value={props.orderTakenBy}
              options={[
                ...Object.values(getUsers()).map((o) => ({ label: o.name.toUpperCase(), value: o.name })),
                ...(isShopifyOrder ? [{ label: 'Shopify', value: 'shopify-store' }] : []),
              ]}
              selectId="edit-order-taken-by"
              selectLabel="Order Taken By"
              key={'order-taken-by-edit-key'}
              error={props.errors.orderTakenBy}
            />
          </div>
        </div>
      </div>
      <div className="w-full p-4 border-collapse border border-secondary mb-2">
        <h5 className="tracking-wide text-secondary-heading mb-4">Customer Details</h5>
        <div className="grid grid-cols-2 grid-rows-2 w-full gap-2">
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Name</h5>
            <div>
              <div className="grid grid-cols-2 gap-2 grid-rows-1">
                <div className="col-span-1">
                  <FloristTextField
                    disabled={isDisabled}
                    label="First Name"
                    onChange={!isShopifyOrder ? props.setCustomerFirstName : undefined}
                    value={props.customerFirstName ?? ''}
                    size="small"
                    fullWidth
                    errorMessage={props.errors.customerFirstName}
                  />
                </div>
                <div className="col-span-1">
                  <FloristTextField
                    label="Last Name"
                    disabled={isDisabled}
                    fullWidth
                    onChange={!isShopifyOrder ? props.setCustomerLastName : undefined}
                    value={props.customerLastName}
                    size="small"
                    errorMessage={props.errors.customerLastName}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Billing Address</h5>
            <FloristAddressField
              label="Billing Address"
              onReset={() => {
                !isShopifyOrder && props.setBillingAddress?.(undefined);
              }}
              selectId="billing-address-select"
              selectLabel="Select Address"
              address={props.billingAddress}
              disabled={isDisabled}
              key={'billing-address-search'}
              onChange={(address) => !isShopifyOrder && props.setBillingAddress(address)}
              error={props.errors.billingAddress}
              showNotesField={false}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Tel Number</h5>
            <FloristPhoneField
              onChange={(e) => {
                !isShopifyOrder && props.setCustomerPhone?.(e);
              }}
              disabled={isDisabled}
              value={props.customerPhone ?? ''}
              error={props.errors.customerPhone}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Email Address</h5>
            <FloristTextField
              fullWidth
              label="Email Address"
              onChange={!isShopifyOrder ? props.setCustomerEmail : undefined}
              disabled={isDisabled}
              value={props.customerEmail ?? ''}
              size="small"
              type={'email'}
              errorMessage={props.errors.customerEmail}
            />
          </div>
        </div>
      </div>
      <div className="w-full p-4 border-collapse border border-secondary mb-2">
        <h5 className="tracking-wide text-secondary-heading mb-4">Delivery Details</h5>
        <div className="grid grid-cols-3 grid-rows-2 gap-2 w-full">
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Method</h5>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="justify-center"
              onChange={(e) => {
                !isShopifyOrder && props.setOrderMethod?.(e.target.value as EOrderMethod);
              }}
            >
              {Object.values(EOrderMethod).map((o, i) => (
                <FormControlLabel
                  slotProps={{ typography: { fontSize: 'small' } }}
                  key={`${i}${o}`}
                  value={o}
                  control={<Radio disabled={isDisabled} checked={props.orderMethod === o} size="small" />}
                  label={o.toUpperCase()}
                />
              ))}
            </RadioGroup>
            {props.errors.orderMethod && <p className="text-error">{`*${props.errors.orderMethod}`}</p>}
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Recipient Name</h5>
            <div>
              <div className="grid grid-cols-2 gap-2 grid-rows-1">
                <div className="col-span-1">
                  <FloristTextField
                    disabled={isDisabled}
                    label="First Name"
                    onChange={!isShopifyOrder ? props.setRecipientFirstName : undefined}
                    value={props.recipientFirstName ?? ''}
                    size="small"
                    fullWidth
                    errorMessage={props.errors.recipientFirstName}
                  />
                </div>
                <div className="col-span-1">
                  <FloristTextField
                    label="Last Name"
                    disabled={isDisabled}
                    fullWidth
                    onChange={!isShopifyOrder ? props.setRecipientLastName : undefined}
                    value={props.recipientLastName}
                    size="small"
                    errorMessage={props.errors.recipientLastName}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">{'Recipient Contact Number'}</h5>
            <FloristPhoneField
              onChange={(e) => {
                !isShopifyOrder && props.setRecipientContact?.(e);
              }}
              value={props.recipientContact ?? ''}
              disabled={isDisabled}
              error={props.errors.recipientContact}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Delivery Address</h5>
            <FloristAddressField
              label="Delivery Address"
              onReset={() => {
                !isShopifyOrder && props.setDeliveryAddressAndDeliveryCost?.(undefined);
              }}
              selectId="delivery-address-select"
              selectLabel="Select Address"
              address={props.deliveryAddress}
              disabled={isShopifyOrder || props.orderMethod !== EOrderMethod.DELIVERY}
              key={'delivery-address-search'}
              onChange={(address) => !isShopifyOrder && props.setDeliveryAddressAndDeliveryCost(address)}
              error={props.errors.deliveryAddress}
              showNotesField={true}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">
              {props.orderMethod === EOrderMethod.DELIVERY ? 'Delivery Date And Time' : 'Collection Date And Time'}
            </h5>
            <FloristDatePicker
              // includeTime
              className="col-span-1"
              disabled={isDisabled}
              label={props.orderMethod === EOrderMethod.DELIVERY ? 'Deliver Date' : 'Collection Date'}
              value={props.fulfillmentDate}
              onChange={(e) => {
                e && !isShopifyOrder && props.setFulfillmentDate?.(e);
              }}
              error={props.errors.fulfillmentDate}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">
              {' '}
              {props.orderMethod === EOrderMethod.DELIVERY ? 'Delivery Time' : 'Collection Time'}
            </h5>
            <RadioGroup
              row
              name={props.orderMethod === EOrderMethod.DELIVERY ? 'delivery-time' : 'collection-time'}
              className="justify-center"
              onChange={(e) => {
                !isShopifyOrder && props.setFulfillmentTime?.(e.target.value as ETimes);
              }}
            >
              {Object.values(ETimes).map((o, i) => (
                <FormControlLabel
                  slotProps={{ typography: { fontSize: 'small' } }}
                  key={`${i}${o}`}
                  value={o}
                  control={<Radio disabled={isDisabled} checked={props.fulfillmentTime === o} size="small" />}
                  label={o.toUpperCase()}
                />
              ))}
            </RadioGroup>
            {props.errors.fulfillmentTime && <p className="text-error">{`*${props.errors.fulfillmentTime}`}</p>}
          </div>
        </div>
      </div>
      <div className="w-full p-4 border-collapse border border-secondary mb-2">
        <h5 className="tracking-wide text-secondary-heading mb-4">Order Details</h5>
        <div className="grid grid-cols-3 grid-rows-4 gap-2 w-full">
          <div className="col-span-1 row-span-2 bg-gray-200">
            <h5 className="text-xs mb-3">Card Message</h5>
            {props.isShopifyOrder ? <p className="text-sm text-light">{props.cardMessage}</p> : (
              <TextareaAutosize
                value={props.cardMessage ?? ''}
                onChange={(e) => {
                  !isShopifyOrder && props.setCardMessage?.(e.target.value);
                }}
                className="border text-base border-secondary w-full !h-20 p-2"
                disabled={isDisabled}
              />
            )}
          </div>
          <div className="col-span-1 row-span-2 bg-gray-200">
            <h5 className="text-xs mb-3">Content</h5>
            {props.isShopifyOrder ? <p className="text-sm text-light">{props.content}</p> : (
              <TextareaAutosize
                value={props.content}
                onChange={(e) => {
                  !isShopifyOrder && props.setContent?.(e.target.value);
                }}
                className="border text-base border-secondary w-full !h-20 p-2"
                disabled={isDisabled}
              />
            )}
          </div>
          <div className="col-span-1 row-span-2 bg-gray-200">
            <h5 className="text-xs mb-3">Item Description</h5>
            <FloristDropdownWithQtyPicker
              options={ITEMS_DESCRIPTION.map((v) => ({ label: v, value: v, qty: 0 }))}
              selectId="edit-order-item-description"
              selectLabel="Item Description"
              onChange={(val) => {
                !isShopifyOrder && props.setItemDescription?.(val.map(v => ({ label: v.label, qty: v.qty })));
              }}
              selectedOptions={props.itemDescription?.map(({ label, qty }) => ({ label, value: label, qty })) || []}
              multiple={true}
              disabled={isDisabled}
              error={props.errors.itemDescription}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Delivery Cost</h5>
            <FloristTextField
              fullWidth
              label="Delivery Cost"
              type={'number'}
              value={typeof props.deliveryCost === 'number' ? props.deliveryCost : ''}
              onChange={(val) => {
                props.fulfilledAt === EStores.HARRODS && props.setDeliveryCost(val && typeof +val === 'number' ? Number(val) : undefined);
              }}
              size="small"
              prefix='£'
              disabled={isDisabled || props.orderMethod === EOrderMethod.COLLECTION || !props.fulfilledAt || props.fulfilledAt !== EStores.HARRODS}
              errorMessage={props.errors.deliveryCost}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Item Price</h5>
            <FloristTextField
              fullWidth
              label="Price"
              type={'number'}
              prefix='£'
              value={typeof props.itemPrice === 'number' ? props.itemPrice : ''}
              onChange={(e) => {
                !isShopifyOrder && props.setItemPrice?.(e && typeof +e === 'number' ? Number(e) : undefined);
              }}
              size="small"
              disabled={isDisabled}
              errorMessage={props.errors.itemPrice}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Total Cost</h5>
            <FloristTextField
              type={'number'}
              value={props.costSummary ?? ''}
              fullWidth
              label="Total Cost"
              size="small"
              prefix='£'
              disabled={true}
              errorMessage={props.errors.costSummary}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Payment Status</h5>
            <FloristDropdown
              onChange={(e) => {
                !isShopifyOrder && props.setPaymentStatus?.(e as EStatus);
              }}
              value={props.paymentStatus ?? ''}
              options={Object.values(EStatus).map((v) => ({ label: v, value: v }))}
              selectId="edit-order-status"
              selectLabel="Payment Status"
              disabled={isDisabled}
              error={props.errors.paymentStatus}
            />
          </div>
          {props.action !== 'create' && (
            <div className="col-span-1 bg-gray-200">
              <h5 className="text-xs mb-3">Fulfillment Status</h5>
              <FloristDropdown
                onChange={(e) => {
                  props.setFulfillmentStatus?.(e as EFulfillmentStatus);
                }}
                value={props.fulfillmentStatus}
                options={Object.values(EFulfillmentStatus).map((v) => ({ label: v, value: v }))}
                selectId="edit-fulfillment-status"
                selectLabel="Fulfillment Status"
                disabled={isCancelled}
                error={props.errors.fulfillmentStatus}
              />
            </div>
          )}
        </div>
      </div>
      {
        <div className="grid grid-cols-4 grid-rows-2 w-full mt-4">
          <div className="col-span-3 row-span-2"></div>
          <FloristButton disabled={isCancelled} onClick={() => props.setIsConfirmDialogOpen !== undefined ? props.setIsConfirmDialogOpen(true) : props.onSubmit()} label="Submit" className="col-span-1 row-span-2" />
        </div>
      }
      {props.isConfirmationDialogOpen && props.setIsConfirmDialogOpen && <ConfirmationDialog
        isOpen={props.isConfirmationDialogOpen}
        title='Confirm Edit'
        content='Are you sure you want to edit this order?'
        onConfirm={() => props.onSubmit()}
        onCancel={() => props.setIsConfirmDialogOpen?.(false)}
      />}
    </div>
  );
}