import { EmptyObject } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IUser } from '../../interfaces/user.interface';
import { TUpdateDto } from './dto/update.dto';
import { IGetOneUserQueryDto } from './dto/get.dto';
import Cookies from 'js-cookie';
import { ITokens } from '../auth/dto/auth.dto';

export const userApi = createApi({
  reducerPath: 'user',
  tagTypes: ['users', 'user'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL || '/api',
    headers: {
      'x-api-key': 'DhKY3vDqDp8!ym$7jUD@K4PXu7PPkd',
    },
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${Cookies.get('access_token')}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUsersList: builder.query<IUser[], EmptyObject>({
      query: () => ({
        url: 'user/list',
      }),
      providesTags: (result) => (result ? result.map(({ _id }) => ({ type: 'users', id: _id })) : ['users']),
    }),
    updateUser: builder.mutation<{ user: IUser; tokens?: ITokens }, TUpdateDto>({
      query: ({ id, ...updateDto }) => ({
        url: `user/${id}`,
        method: 'PATCH',
        body: updateDto,
      }),
      invalidatesTags: (result) => [
        { type: 'user', id: result?.user?._id },
        { type: 'users', id: result?.user?._id },
      ],
    }),
    getUser: builder.query<{ user: IUser; tokens?: ITokens }, IGetOneUserQueryDto>({
      query: (filters) => ({
        url: 'user',
        params: filters,
      }),
      transformResponse: (response: { user: IUser; tokens?: ITokens }) => {
        if (response.tokens?.accessToken) Cookies.set('access_token', response.tokens.accessToken);
        return response;
      },
      providesTags: (result) => (result ? [{ type: 'user', id: result.user._id }] : ['user']),
    }),
    deleteUser: builder.mutation<IUser, string>({
      query: (id) => ({
        url: `user/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [{ type: 'user', id: result?._id }, { type: 'users' }],
    }),
  }),
});

export const { useGetUsersListQuery, useDeleteUserMutation, useUpdateUserMutation, useGetUserQuery } = userApi;
