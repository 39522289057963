import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { INotification } from '../interfaces/notification.interface';
import { useGetUserQuery } from '../store/user/user.api';
import { ENotificationTypes } from '../types/enums/notification-type.enum';

const getToastMessage = (notification: INotification) => {
  switch (notification.notificationType) {
    case ENotificationTypes.ORDER_REASSIGN:
      return '🔔 New Order Assigned to You Store';
    default:
      return '🔔 New Notification';
  }
};
export const useSSE = () => {
  const clientId = uuidv4();
  const { data: currentUser } = useGetUserQuery({});
  const [receivedNotification, setReceivedNotification] = useState<INotification>();

  useEffect(() => {
    if (currentUser) {
      const eventSource = new EventSource(
        `${
          process.env.REACT_APP_SERVER_URL || '/api'
        }/notifications/sse?clientId=${clientId}&userId=${currentUser?.user?._id}`
      );

      eventSource.onmessage = async (event) => {
        const data: INotification = JSON.parse(event.data);
        // Skip heartbeat notifications
        if (!('heartbeat' in data)) {
          setReceivedNotification(data);
          toast(getToastMessage(data), {
            position: 'top-center',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            type: 'default',
            className: '!bg-primary text-center',
          });
        }
      };

      eventSource.onerror = () => {
        toast('‼️ An error occurred with notifications. Please refresh the page.', { type: 'error' });
        eventSource.close();
      };

      return () => {
        eventSource.close();
      };
    }
  }, [currentUser]);

  return {
    notification: receivedNotification,
  };
};
