import * as React from 'react';
import { FloristTable } from '../../components/base/Table';
import moment from 'moment';
import { EOrderMethod } from '../../types/enums/order-method.enum';
import { ETimes } from '../../types/enums/time.enum';
import { EStores } from '../../types/enums/taken-by.enum';
import ShopifyLogo from '../../assets/logo/shopify.png';
import HarrodsLogo from '../../assets/logo/harrods.webp';
import StudioLogo from '../../assets/logo/studio.png';
import BelgraviaLogo from '../../assets/logo/belgravia.png';
import MayFairLogo from '../../assets/logo/mayfair.png';
import { IOrder } from '../../interfaces/order.interface';
import { isFulfilled } from '../../utils/fulfillment.util';
import { EOrderStatus } from '../../types/enums/status.enum';
import AllOrderFilters from '../../components/AllOrderFilters';
import { concatName } from '../../utils/name.util';
import { useOrders } from '../order/useOrders';
import { IFilters } from '../../interfaces/order-filters.interface';
import { formatAmount } from '../../utils/amount.util';

interface IOrderSummary {
    'ORDER ID': unknown;
    'FULFILLMENT DATE': string;
    'CUSTOMER NAME': string;
    'RECIPIENT NAME': string;
    METHOD: EOrderMethod;
    'TOTAL VALUE': string;
    'ACTIONS': { onClick: () => void, label: string }[];
    isDone?: boolean;
    isCancelled: boolean;
}
export default function ArchivedOrders() {

    const getLogo = (order: IOrder) => {
        if (order.fulfilledAt === EStores.HARRODS)
            return HarrodsLogo;
        if (order.fulfilledAt === EStores.MAYFAIR_BOUTIQUE)
            return MayFairLogo;
        if (order.fulfilledAt === EStores.BELGRAVIA_BOUTIQUE)
            return BelgraviaLogo;
        if (order.fulfilledAt === EStores.STUDIO)
            return StudioLogo;
        if (order.fulfilledAt === EStores.SHOPIFY)
            return ShopifyLogo

    }
    const { limit, page, records, total, setLimit, setPage, navigate, filters, setFilters, currentUser } = useOrders({ orderStatus: EOrderStatus.DELETED });
    return (
        <div className="flex flex-col">
            <AllOrderFilters
                currentUser={currentUser}
                filters={filters}
                setFilters={(filters: IFilters) => setFilters({ ...filters, 'order-status': EOrderStatus.DELETED })}
            />
            <FloristTable<IOrderSummary>
                limit={limit ?? 0}
                total={total ?? 0}
                page={page ?? 0}
                onPageChange={setPage}
                onLimitChange={(n) => {
                    setLimit(n);
                    setPage(1);
                }}
                data={
                    records?.map((r) => ({
                        isDone: isFulfilled(r),
                        isCancelled: r.orderStatus === EOrderStatus.CANCELLED,
                        'ORDER ID': <div className='grid grid-cols-2 items-center'>{r.shopifyData?.nsfcId || `#${r._id}`} {<img width={40} height={20} src={getLogo(r)} />}</div>,
                        'CUSTOMER NAME': concatName(r.customer?.firstName, r.customer?.lastName),
                        'FULFILLMENT DATE': `${moment(r.fulfillmentDate).format('DD/MM/yyyy')} (${r.fulfillmentTime || ETimes.NOT_SPECIFIED
                            })`,
                        METHOD: r.method,
                        'TOTAL VALUE': typeof r.costSummary === 'number' ? formatAmount(r.costSummary
                        ) : 'Not available',
                        'RECIPIENT NAME': concatName(r.recipientFirstName, r.recipientLastName),
                        'ACTIONS': [{ onClick: () => navigate(`/order/view/${r._id}`), label: 'View' }]
                    })) ?? []
                }
            />
        </div>
    );

}
