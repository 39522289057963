import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function ProfileDropDown(props: { logout: () => void }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  // Function to handle the click on the ExpandMoreIcon
  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle the menu item click (e.g., logout)
  const handleLogout = () => {
    Cookies.remove('access_token');
    props.logout();
    navigate('/');
    setAnchorEl(null);
  };

  // Function to handle the menu close
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        color="inherit" // You can change the color as needed
        onClick={handleIconClick}
      >
        <ExpandMoreIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleLogout}>
          <LogoutIcon fontSize="medium" className="m-1" />
          <h1 className="text-xs">Logout</h1>
        </MenuItem>
        {/* You can add more menu items here if needed */}
      </Menu>
    </div>
  );
}

export default ProfileDropDown;
