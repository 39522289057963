import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useLoginMutation } from '../../store/auth/auth.api';
import Cookies from 'js-cookie';
import { useGetUserQuery } from '../../store/user/user.api';

export const useLogin = () => {
  const [login, { isLoading, data: loginData }] = useLoginMutation();
  const navigate = useNavigate();
  const checkIfAlreadyLoggedIn = () => {
    const token = Cookies.get('access_token');
    if (token) {
      const { data } = useGetUserQuery({}, { refetchOnMountOrArgChange: true });
      useEffect(() => {
        if (data?.user) navigate('/all-orders');
      }, [data]);
    }
  };
  checkIfAlreadyLoggedIn();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  useEffect(() => {
    if (loginData) {
      Cookies.set('access_token', loginData.tokens.accessToken);
      if (Cookies.get('access_token')) navigate('/all-orders');
    }
  }, [loginData]);
  const onSubmit = async () => {
    // Reset Errors
    await login({ email, password });
  };

  const onForgotPassword = () => {
    navigate('/forgot-password');
  };
  return {
    onSubmit,
    email,
    password,
    setEmail,
    setPassword,
    isLoading,
    onForgotPassword,
  };
};
