// ConfirmationDialog.tsx
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FloristButton } from './Button';

interface ConfirmationDialogProps {
    isOpen: boolean;
    title: string;
    content: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    isOpen,
    title,
    content,
    onConfirm,
    onCancel,
}) => {
    return (
        <Dialog open={isOpen} onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <FloristButton
                    label='Cancel'
                    className='!text-lg'
                    onClick={onCancel}
                    color="primary" />
                <FloristButton
                    label='Confirm'
                    className='!text-lg'
                    onClick={onConfirm}
                    color="secondary"
                    autoFocus />
            </DialogActions>
        </Dialog>
    );
};
