/* eslint-disable @typescript-eslint/no-empty-function */
import { IconButton, TextField } from '@mui/material';
import * as React from 'react';
import { FloristButton } from '../../components/base/Button';
import EditIcon from '@mui/icons-material/Edit';
import { useUserSettings } from './useSettings';

export function Settings() {
  const logic = useUserSettings();
  if (!logic) return <></>;
  const {
    email,
    firstName,
    handleUpdate,
    isEditAble,
    lastName,
    role,
    setEditAble,
    setEmail,
    setFirstName,
    setLastName,
  } = logic;
  return (
    <div>
      <div className="w-full p-4 border-collapse border border-secondary mb-2">
        {isEditAble ? (
          <div className="grid grid-cols-4 grid-rows-2 gap-y-5 gap-x-10">
            <TextField
              className="col-span-2"
              size="small"
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              className="col-span-2"
              size="small"
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              className="col-span-2"
              size="small"
              label="Email"
              value={email}
              type={'email'}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* <FloristDropdown
              className="col-span-2"
              options={Object.entries(ERole).map(([k, v]) => ({ label: k, value: v }))}
              value={role}
              onChange={(val) => {
                setRole(val as ERole);
              }}
              selectId={'user-role-select'}
              selectLabel={'User Role'}
              key={'user-role-setting'}
            /> */}
            <div className="flex flex-row justify-center gap-4 col-span-2 col-start-2">
              <FloristButton className="!bg-error" label="Cancel" onClick={() => setEditAble(false)} />
              <FloristButton label="Update" onClick={handleUpdate} />
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-row justify-between">
              <h5 className="tracking-widest text-2xl">{`${firstName} ${lastName}`}</h5>
              <IconButton onClick={() => setEditAble(true)}>
                <EditIcon />
              </IconButton>
            </div>
            <p className="text-secondary-heading text-xs capitalize">{role}</p>
            <h5 className="tracking-wide text-base mt-4">{'Email'}</h5>
            <p className="text-secondary-heading text-xs">{email}</p>
          </>
        )}
      </div>
    </div>
  );
}
