import * as React from 'react';

export interface IInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string;
  id: string;
  labelClassNames?: string;
}

export function FloristInput({ id, label, labelClassNames = '', ...extra }: IInputProps) {
  return (
    <div className="mb-4">
      <label
        htmlFor={`${id.toLowerCase()}`}
        className={`block text-gray-700 text-sm mb-2 font-extraLight ${labelClassNames}`}
      >
        {label}
      </label>
      <input
        id={id}
        type={extra.type}
        className={`w-full border border-border border-solid px-2 ${extra.className}`}
        {...extra}
      />
    </div>
  );
}
