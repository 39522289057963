import { IOrder } from './../../interfaces/order.interface';
import { useNavigate } from 'react-router-dom';
import { IAddress } from './../../interfaces/address.interface';
import { EStatus } from '../../types/enums/payment-status.enum';
import moment, { Moment } from 'moment';
import { useState, useEffect } from 'react';
import { EOrderMethod } from '../../types/enums/order-method.enum';
import { useCreateOrderMutation } from '../../store/order/order.api';
import { TErrors } from './Order';
import { EStores } from '../../types/enums/taken-by.enum';
import { getDeliveryCost } from '../../utils/delivery-cost.util';
import { ETimes } from '../../types/enums/time.enum';
import { useGetUserQuery } from '../../store/user/user.api';
import { EOrderStatus } from '../../types/enums/status.enum';
import { IOrderItem } from '../../interfaces/item.interface';

export const useNewOrder = () => {
  const navigate = useNavigate();
  const [createOrder] = useCreateOrderMutation();
  const { data: currentUser } = useGetUserQuery({});
  const [fulfilledAt, setFulfilledAt] = useState<EStores>();
  const [orderTakenBy, setOrderTakenBy] = useState<IOrder['takenBy']>();
  const [orderDate, setOrderDate] = useState<Moment>(moment());
  const [customerFirstName, setCustomerFirstName] = useState<string>();
  const [customerLastName, setCustomerLastName] = useState<string>();
  const [billingAddress, setBillingAddress] = useState<IAddress>();
  const [customerPhone, setCustomerPhone] = useState<string>();
  const [customerEmail, setCustomerEmail] = useState<string>();
  const [orderMethod, setOrderMethod] = useState<EOrderMethod>();
  const [fulfillmentDate, setFulfillmentDate] = useState<Moment>(moment());
  const [fulfillmentTime, setFulfillmentTime] = useState<ETimes>();
  const [recipientFirstName, setRecipientFirstName] = useState<string>();
  const [recipientLastName, setRecipientLastName] = useState<string>();
  const [deliveryAddress, setDeliveryAddress] = useState<IAddress>();
  const [recipientContact, setRecipientContact] = useState<string>();
  const [deliveryCost, setDeliveryCost] = useState<number>();
  const [cardMessage, setCardMessage] = useState<string>();
  const [itemDescription, setItemDescription] = useState<IOrderItem[]>([]);
  const [paymentStatus, setPaymentStatus] = useState<EStatus>();
  const [itemPrice, setItemPrice] = useState<number>();
  const [costSummary, setCostSummary] = useState<number>();
  const [content, setContent] = useState<string>();
  const [errors, setErrors] = useState<TErrors>({});
  useEffect(() => {
    // props.currentUser?.role !== ERole.ADMIN ? props.currentUser?.store :
    if (currentUser?.user?.store && !fulfilledAt) setFulfilledAt(currentUser.user?.store);
  }, [currentUser]);
  useEffect(() => {
    setCostSummary((deliveryCost ?? 0) + (itemPrice ?? 0));
  }, [deliveryCost, itemPrice]);
  // Whenever any value is changed
  useEffect(() => {
    // Only when errors have already been displayed. We don't want to show errors before button is clicked
    if (Object.keys(errors).length) {
      const errors = validate();
      setErrors(errors);
    }
  }, [
    fulfilledAt,
    orderTakenBy,
    orderDate,
    customerFirstName,
    customerLastName,
    billingAddress,
    customerPhone,
    customerEmail,
    orderMethod,
    fulfillmentDate,
    fulfillmentTime,
    recipientFirstName,
    recipientLastName,
    recipientContact,
    deliveryCost,
    cardMessage,
    itemDescription,
    content,
    paymentStatus,
    itemPrice,
    costSummary,
  ]);
  const validate = () => {
    let errors = {};
    if (typeof costSummary !== 'number') errors = { ...errors, costSummary: 'Is Required' };
    if (orderMethod === EOrderMethod.DELIVERY && !deliveryAddress)
      errors = { ...errors, deliveryAddress: 'Is Required' };
    if (!fulfillmentDate) errors = { ...errors, fulfillmentDate: 'Is Required' };
    if (!orderDate) errors = { ...errors, orderDate: 'Is Required' };
    if (!orderMethod) errors = { ...errors, orderMethod: 'Is Required' };
    if (!paymentStatus) errors = { ...errors, paymentStatus: 'Is Required' };
    if (!fulfilledAt) errors = { ...errors, fulfilledAt: 'Is Required' };
    if (!orderTakenBy) errors = { ...errors, orderTakenBy: 'Is Required' };
    if (orderMethod === EOrderMethod.DELIVERY) {
      if (!recipientContact) {
        errors = { ...errors, recipientContact: 'Is Required' };
      }
      if (!recipientFirstName) {
        errors = { ...errors, recipientFirstName: 'Is Required' };
      }
      if (!recipientLastName) {
        errors = { ...errors, recipientLastName: 'Is Required' };
      }
      // Only harrods can edit the delivery cost manually
      if (typeof deliveryCost !== 'number' && fulfilledAt === EStores.HARRODS) {
        errors = { ...errors, deliveryCost: 'Is Required' };
      }
    }
    if (typeof itemPrice !== 'number') errors = { ...errors, itemPrice: 'Is Required' };
    if (!itemDescription?.length) errors = { ...errors, itemDescription: 'Is Required' };
    return errors;
  };
  const onSubmit = async () => {
    const errors = validate();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    if (orderMethod && costSummary && paymentStatus && fulfilledAt && orderTakenBy && typeof itemPrice === 'number')
      await createOrder({
        costSummary,
        customer: {
          billingAddress,
          email: customerEmail,
          firstName: customerFirstName,
          lastName: customerLastName,
          phone: customerPhone,
        },
        fulfillmentDate: fulfillmentDate?.toDate(),
        fulfillmentTime: fulfillmentTime || ETimes.NOT_SPECIFIED,
        deliveryAddress: orderMethod === EOrderMethod.DELIVERY ? deliveryAddress : undefined,
        method: orderMethod,
        orderedAt: orderDate?.toDate(),
        recipientFirstName: recipientFirstName,
        recipientLastName: recipientLastName,
        recipientPhone: recipientContact,
        status: paymentStatus,
        fulfilledAt: fulfilledAt,
        takenBy: orderTakenBy,
        card: cardMessage ? { message: cardMessage } : undefined,
        content,
        deliveryCost,
        itemDescription,
        itemPrice: itemPrice,
        orderStatus: EOrderStatus.ACTIVE,
      });
    navigate('/all-orders');
  };
  const setDeliveryAddressAndDeliveryCost = (address?: IAddress) => {
    if (address && address.postCode) {
      const deliverCost = getDeliveryCost(address.postCode);
      if (typeof deliverCost !== 'number') setErrors({ ...errors, deliveryAddress: 'We do not deliver to this area.' });
      else if (fulfilledAt && fulfilledAt !== EStores.HARRODS) {
        setDeliveryCost(getDeliveryCost(address.postCode));
      }
    } else {
      setErrors({ ...errors, deliveryAddress: undefined });
    }
    setDeliveryAddress(address);
  };
  const setOrderMethodAndCost = (method?: EOrderMethod) => {
    setOrderMethod(method);
    // If collection then there is no delivery fee
    if (method === EOrderMethod.COLLECTION) setDeliveryCost(0);
    else if (fulfilledAt !== EStores.HARRODS) {
      if (deliveryAddress?.postCode) {
        setDeliveryCost(getDeliveryCost(deliveryAddress.postCode));
      } else {
        setDeliveryCost(undefined);
      }
    }
  };
  const handleStoreChange = (newStore: EStores) => {
    if (orderMethod === EOrderMethod.COLLECTION) setDeliveryCost(0);
    else if (newStore === EStores.HARRODS) setDeliveryCost(undefined);
    else if (deliveryAddress?.postCode) {
      setDeliveryCost(getDeliveryCost(deliveryAddress.postCode));
    }
    setFulfilledAt(newStore);
  };
  return {
    orderDate,
    fulfilledAt,
    orderTakenBy,
    customerFirstName,
    customerLastName,
    billingAddress,
    customerPhone,
    customerEmail,
    orderMethod,
    deliveryAddress,
    recipientContact,
    recipientFirstName,
    recipientLastName,
    costSummary,
    cardMessage,
    deliveryCost,
    itemDescription,
    paymentStatus,
    itemPrice,
    content,
    fulfillmentDate,
    fulfillmentTime,
    setOrderDate,
    handleStoreChange,
    setOrderTakenBy,
    setCustomerFirstName,
    setCustomerLastName,
    setBillingAddress,
    setCustomerPhone,
    setCustomerEmail,
    setOrderMethod: setOrderMethodAndCost,
    setDeliveryAddressAndDeliveryCost,
    setRecipientContact,
    setRecipientFirstName,
    setRecipientLastName,
    setCostSummary,
    setCardMessage,
    setDeliveryCost,
    setItemDescription,
    setPaymentStatus,
    setItemPrice,
    setContent,
    setFulfillmentDate,
    setFulfillmentTime,
    onSubmit,
    errors,
    currentUser: currentUser?.user,
  };
};
