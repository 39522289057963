import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import * as React from 'react';

export interface IFloristDropdownProps {
  options: Array<{ label: string; value: string }>;
  selectId: string;
  selectLabel: string;
  onChange: (value: string | string[]) => void;
  value?: string | string[];
  disabled?: boolean;
  className?: string;
  error?: string;
  multiple?: boolean;
}

export function FloristDropdown(props: IFloristDropdownProps) {
  return (
    <FormControl size="small" className={`w-full ${props.className}`} disabled={props.disabled}>
      <InputLabel id={`${props.selectId}-label`}>{props.selectLabel}</InputLabel>
      {props.multiple ? (
        <Select
          labelId={`${props.selectId}-label`}
          id={props.selectId}
          onChange={(e) => props.onChange(e.target.value)}
          autoWidth
          label={props.selectLabel}
          multiple={props.multiple}
          value={props.value}
          renderValue={(selected) => (Array.isArray(selected) ? selected.join(', ') : selected)}
        >
          {props.options.map((o, i) => (
            <MenuItem key={`${o.label}-${i}`} value={o.label}>
              <Checkbox checked={props.value?.includes(o.value)} />
              <ListItemText primary={o.label} />
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          labelId={`${props.selectId}-label`}
          id={props.selectId}
          value={props.value || ''}
          onChange={(e) => props.onChange(e.target.value)}
          autoWidth
          label={props.selectLabel}
        >
          {props.options.map((o, i) => (
            <MenuItem key={`${o.label}${i}`} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </Select>
      )}
      {props.error && <p className="text-error">{`*${props.error}`}</p>}
    </FormControl>
  );
}
