import React from 'react';
import { INotification } from '../../interfaces/notification.interface';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';

interface NotificationItemProps {
    notification: INotification;
    onNotificationItemClick: (id: string) => void;
    onNotificationRemove: (id: string) => void;
}

// Styled component for hover effect
const HoverPaper = styled(Paper)(({ theme }) => ({
    transition: theme.transitions.create('box-shadow', {
        duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
        boxShadow: theme.shadows[5], // Adjust shadow elevation here
    },
}));

export const NotificationItem: React.FC<NotificationItemProps> = ({ notification, onNotificationItemClick, onNotificationRemove }) => {
    return (
        <HoverPaper
            onClick={() => {
                if (notification._id)
                    onNotificationItemClick(notification.subjectId);
            }}
            elevation={1}
            className='p-2 mb-3 cursor-pointer flex items-center hover:!bg-primary'
        >
            <span className='text-base font-semibold'>
                {notification.message}
            </span>
            <IconButton
                onClick={(e) => {
                    if (notification._id) {
                        onNotificationRemove(notification._id)
                        e.stopPropagation()
                    }
                }}
                size="small"
            >
                <CancelIcon color='warning' />
            </IconButton>
        </HoverPaper>
    );
};